import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/layouts/main-layout";
import SportsLayout from "../../components/layouts/sports-layout";
import CourseInfo from "../../components/course-info";
import Seo from "../../components/seo";

const SUP = () => {
  const headerImage = (
    <StaticImage
      src="../../images/heroimages/tidens_website_SUP_header.jpg"
      alt="SUP Kurse bei Tidens Surfhuus"
      width={2048}
      height={680}
      quality={90}
      className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
      objectFit="cover"
    />
  );

  const description = (
    <div>
      Stand-Up Paddling oder SUP ist die Wassersportart für die ganze Familie.
      Außer Schwimmkenntnissen sind keine besonderen Voraussetzungen für das
      Stand-Up Paddling erforderlich. Du kannst es ganz gemütlich angehen lassen
      und meditativ in den Sonnenuntergang paddeln oder mit Freunden ein
      sportliches Full-Body-Workout absolvieren. SUPen ist eine tolle
      Alternative für windstille Tage ohne Welle – sogenannte "glassy days“.
    </div>
  );

  const rentalDescription =
    "Du kannst dir bei uns auch ein SUP-Board leihen. Wenn Du noch keine Erfahrung im Stand-Up Paddling hast, erhältst Du von uns während der Verleihzeit eine Sicherheitseinweisung.";

  const courses = [
    <CourseInfo
      title="Schnupperkurs"
      price="35"
      duration="1h"
      people="6"
      description="Du möchtest Stand-Up Paddling einfach einmal ausprobieren? Gemeinsam paddeln wir die ersten Meter auf dem SUP-Board. Auch als Familienaktivität ist Stand-Up Paddling sehr beliebt und hervorragend für Kinder geeignet."
      sport="SUP"
      type="TRY"
      requestOnly={false}
    />,
    <CourseInfo
      title="VDWS Grundkurs"
      price="85"
      duration="3h"
      people="6"
      description="Im VDWS Grundkurs lernst Du verschiedene Paddeltechniken sowie Hintergrundwissen zum Stap Up Paddling. Nach diesem Kurs kannst du bei uns eine VDWS-Lizenz ablegen."
      sport="SUP"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="Sonnenuntergangstour"
      price="60"
      duration="1,5h"
      people="8"
      description="Bei geeigneten Bedingungen bieten wir auch eine geführte Sonnenuntergangstour mit dem SUP an. Voraussetzung für die Teilnahme sind erste Erfahrungen im Stand-Up Paddling (z. B. Schnupperkurs)."
      requestOnly={true}
    />,
    <CourseInfo
      title="Privatstunde"
      price="99"
      duration="1h"
      people="1"
      description="Du möchtest etwas ganz Bestimmtes lernen oder bist einfach nur auf der Suche nach intensiver Betreuung? Gerne unterrichten wir dich auch in einer Privatstunde. Bitte beachte, dass wir Privatstunden nur auf Anfrage anbieten können."
      requestOnly={true}
    />,
    <CourseInfo
      title="VDWS Lizenz"
      price="30"
      duration="1h"
      people="6"
      description="Im Anschluss an den Grundkurs kannst du bei uns auch eine VDWS-Lizenz machen. Diese gilt als Nachweis für deine Fähigkeiten im Bereich Stand Up Paddling und wird zum Beispiel für das Ausleihen von Material benötigt. Im Preis sind die Prüfung sowie die Lizenz enthalten."
      requestOnly={true}
    />,
    <CourseInfo
      title="VDWS Junior Lizenz"
      price="20"
      duration="1h"
      people="6"
      description="Auch Kinder können bei uns im Anschluss an den Grundkurs eine VDWS-Lizenz machen. Diese ist bis zu einem Alter von 14 Jahren gültig und kann anschließend mit einer Theorieprüfung in den offiziellen VDWS-Grundschein überführt werden. Im Preis sind die Prüfung sowie die Junior-Lizenz enthalten."
      requestOnly={true}
    />,
  ];

  const image1 = (
    <StaticImage
      src="../../images/galery/sup/tidens_sup_galery_1.jpg"
      alt="SUPen Fahren lernen bei Tidens Surfhuus Galery 1"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image2 = (
    <StaticImage
      src="../../images/galery/sup/tidens_sup_galery_2.jpg"
      alt="SUPen Fahren lernen bei Tidens Surfhuus Galery 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const images = [image1, image2];

  return (
    <Layout>
      <Seo
        title="Stand Up Paddling (SUP) Kurse für Anfänger und Fortgeschrittene"
        description="Du möchtest SUP Fahren lernen? Wir bieten SUP für Anfänger und Fortgeschrittene auf der Nordseeinsel Langeoog an."
      />
      <SportsLayout
        title="Stand Up Paddling"
        sport="sup"
        image={headerImage}
        description={description}
        rental_description={rentalDescription}
        courses={courses}
        images={images}
      />
    </Layout>
  );
};

export default SUP;
